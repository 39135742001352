export type ZipCode = number;

export const zipCodesKasselArea: ZipCode[] = [
  34117,
  34125,
  34119,
  34121,
  34123,
  34134,
  34233,
  34355,
  34127,
  34195,
  34114,
  34194,
  34197,
  34106,
  34196,
  34109,
  34113,
  34110,
  34105,
  34108,
  34111,
  34115,
  34198,
  34112,
  34132,
  34130,
  34131,
  34128,
  34266,
  34246,
  34277,
  34253,
  34260,
  34225,
  34292,
  34314,
  34265,
  34320,
  34270,
  34393,
  34346,
  34302,
  34219,
  34317,
  34376,
  34295,
  34379,
  34329,
  34289,
  34587,
  34281,
  34327,
  34305,
  34298,
  37235,
  34212,
  34369,
  34466,
  34308,
  34359,
  37217,
  34209,
  34396,
  34560,
  37247,
  37127,
  34311,
  34286,
  34479,
  37216,
  34590,
  37218,
  34323,
  37213,
  37124,
  34471,
  37215,
  34388,
  34576,
  34326,
  34414,
  37133,
  34434,
  37242,
  34454,
  37249,
  37214,
  34549,
  34399,
  37284,
  34582,
  34593,
  37170,
  34537,
  37139,
  37297,
  36211,
  34513,
  37290,
  37194,
  37318,
  36199,
  37079,
  34516,
  34385,
  34596,
  34535,
  37688,
  34474,
  34477,
  36219,
  36205,
  34621,
  37287,
  34439,
  34599,
  36179,
  36251,
  37269,
  37697,
  37120,
  37181,
  37081,
  37130,
  37085,
  37083,
  37276,
  34632,
  34497,
  37691,
  33034,
  36286,
  37075,
  37096,
  37099,
  37095,
  37097,
  37073,
  37098,
  37100,
  37267,
  37077,
  36214,
  34431,
  34613,
  34639,
  37308,
  37296,
  37176,
  35114,
  37671,
  34626,
  34519,
  37699,
  36177,
  34494,
  34495,
  37186,
  34611,
  35110,
  36208,
  34630,
  36280,
  36217,
  37136,
  33165,
  36275,
  34628,
];

import component from './DataEntry.vue';
import { StepId } from '~/consts/assistant-steps';
import {
  FormMultipleChoiceValueType,
  FormSelectValueType,
  FormTextFieldValueType,
  FormUploadFieldValueType,
  StepDefinition,
} from '@/interfaces/step-definition';
import { AssistantStepContext } from '@/interfaces/context';
import {
  buildFormFileUploadField,
  buildFormMultipleChoiceField,
  buildFormSelect,
  buildFormTextField,
} from '@/lib/forms/builder';
import i18n from '@/plugins/i18n';
import { ValidationType } from '@/consts/validation-types';

export interface DataEntryStepContext extends AssistantStepContext {
  zipCode: FormTextFieldValueType;
  averageAnnualConsumption: FormTextFieldValueType;
  realEstateConstructionYear: FormTextFieldValueType;
  installedPhotovoltaicSystem: FormMultipleChoiceValueType;
  buildingOwner: FormMultipleChoiceValueType;
  photovoltaicTarget: FormMultipleChoiceValueType;
  miscellaneousInput: FormTextFieldValueType;
  modulePlacement: FormMultipleChoiceValueType;
  alignmentRoof: FormSelectValueType;
  filesUpload: FormUploadFieldValueType;
  chargingStationInterest: FormMultipleChoiceValueType;
}

const MAX_CONSTRUCTION_YEAR = new Date().getFullYear();

const config: StepDefinition = {
  beforeNext: (context) => {
    if (!context.dataEntry.photovoltaicTarget.includes('miscellaneous')) {
      context.dataEntry.miscellaneousInput = '';
    }
    return true;
  },
  component,
  fields: [
    buildFormTextField('zipCode', {
      required: true,
      showDescription: true,
      validation: [ValidationType.postalCodeDE],
    }),
    buildFormTextField('averageAnnualConsumption', {
      component: {
        min: 0,
        suffix: i18n.t(
          'dataEntry.formFields.averageAnnualConsumption.suffix',
        ) as string,
        type: 'number',
      },
      required: true,
      validation: [ValidationType.numeric],
    }),
    buildFormTextField('realEstateConstructionYear', {
      component: {
        max: MAX_CONSTRUCTION_YEAR,
        min: 0,
        type: 'number',
      },
      required: true,
      validation: [
        ValidationType.numeric,
        {
          errorLabel: {
            label: i18n.t('realEstateConstructionYear.maxYear') as string,
            namedFormatter: { year: new Date().getFullYear() },
          },
          validator: (value) => Number(value) <= MAX_CONSTRUCTION_YEAR,
        },
      ],
    }),
    buildFormMultipleChoiceField('installedPhotovoltaicSystem', {
      component: {
        columns: 2,
        options: [
          {
            value: 'yes',
          },
          {
            value: 'no',
          },
        ],
        singleAnswer: true,
      },
      required: true,
    }),
    buildFormMultipleChoiceField('buildingOwner', {
      component: {
        columns: 2,
        options: [
          {
            value: 'yes',
          },
          {
            value: 'no',
          },
        ],
        singleAnswer: true,
      },
      required: true,
    }),
    buildFormMultipleChoiceField('photovoltaicTarget', {
      component: {
        columns: 5,
        options: [
          {
            value: 'detachedHouse',
          },
          {
            value: 'townHouse',
          },
          {
            value: 'apartmentBuilding',
          },
          {
            value: 'apartment',
          },
          {
            value: 'miscellaneous',
          },
        ],
        singleAnswer: true,
      },
      required: true,
    }),
    buildFormTextField('miscellaneousInput', {
      outputFormatter: (data) => {
        if (data.assistantContext.dataEntry.miscellaneousInput === '') {
          return {
            formattedLabel: false,
            formattedValue: false,
          };
        }
        return {
          formattedLabel: data.formattedLabel,
          formattedValue: data.formattedValue,
        };
      },
    }),
    buildFormMultipleChoiceField('modulePlacement', {
      component: {
        columns: 4,
        options: [
          {
            value: 'houseRoof',
          },
          {
            value: 'gardenHouse',
          },
          {
            value: 'carportGarage',
          },
          {
            value: 'miscellaneous',
          },
        ],
        singleAnswer: true,
      },
      required: true,
    }),
    buildFormSelect('alignmentRoof', {
      component: {
        items: [
          'eastWest',
          'south',
          'west',
          'southEast',
          'east',
          'southWest',
          'north',
          'flatRoof',
          'miscellaneous',
        ],
      },
      required: true,
    }),
    buildFormFileUploadField('filesUpload', {
      component: {
        accept: 'image/*',
        maxFileSizeInBytes: 10 * 1024 * 1024, // 10 MiB
        multiple: true,
      },
      required: true,
    }),
    buildFormMultipleChoiceField('chargingStationInterest', {
      component: {
        columns: 2,
        options: [
          {
            value: 'yes',
          },
          {
            value: 'no',
          },
        ],
        singleAnswer: true,
      },
      required: true,
    }),
    buildFormMultipleChoiceField('roofType', {
      component: {
        columns: 5,
        options: [
          {
            image: {
              file: 'roof1.svg',
              options: {
                height: 100,
                width: 136,
              },
            },
            value: 'gabledRoof',
          },
          {
            image: {
              file: 'roof2.svg',
              options: {
                height: 100,
                width: 136,
              },
            },
            value: 'hipRoof',
          },
          {
            image: {
              file: 'roof3.svg',
              options: {
                height: 100,
                width: 136,
              },
            },
            value: 'halfHippedRoof',
          },
          {
            image: {
              file: 'roof4.svg',
              options: {
                height: 100,
                width: 136,
              },
            },
            value: 'pentRoof',
          },
          {
            image: {
              file: 'roof5.svg',
              options: {
                height: 100,
                width: 136,
              },
            },
            value: 'flatRoof',
          },
        ],
        singleAnswer: true,
      },
      required: true,
    }),
  ],
  hideSkipMessage: () => true,
  id: StepId.DATA_ENTRY,
};

export default config;

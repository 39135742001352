import axios from 'axios';
import { getUploadedFiles } from '@/lib/submit/upload-files';
import { AssistantContext } from '~/steps/steps';
import { StepDefinition } from '@/interfaces/step-definition';

export interface ProductRequest {
  city: string;
  comment: string;
  email: string;
  firstName: string;
  lastName: string;
  phone: string;
  productId: string;
  productUrl: string;
  salutationDisplayName: string;
  street: string;
  zipCode: string;
}

export const sendProductRequest = async (
  steps: StepDefinition[],
  context: AssistantContext,
  productRequest: ProductRequest,
): Promise<void> => {
  const mailAttachments = async (): Promise<string[]> => {
    try {
      return await getUploadedFiles(context);
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
      throw new Error(
        'An error occurred while reading Files-Upload from s3-bucket.',
      );
    }
  };

  try {
    await axios.post(process.env.VUE_APP_FUNCTIONS_URL + '/productRequest', {
      dataAttachments: await mailAttachments(),
      productRequest,
    });
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error(e);
    throw new Error('An error occurred while sending the product request.');
  }
};

import { AssistantContext } from '~/steps/steps';
import { StepCategoryId, StepId } from '~/consts/assistant-steps';
import { FormFieldType } from '@/consts/input-types';
import {
  FieldOutputFormatterReturn,
  FormCheckboxComponentConfig,
  FormCheckboxValueType,
  FormField,
  FormMultipleChoiceComponentConfig,
  FormTextFieldValueType,
  FormUploadFieldValueType,
  FormValueType,
  FormattedData,
  FormattedDataSummary,
  StepDefinition,
} from '@/interfaces/step-definition';
import i18n from '@/plugins/i18n';
import { getStepContext } from '@/lib/context';
import { MultipleChoiceValue } from '@/interfaces/context';
import { OutputFormatterTypes } from '@/consts/output-formatter-types';
import { StepTypes } from '@/consts/step-types';

const getStepTitle = (stepId: StepId | string) => i18n.t(`${stepId}.title`);

const getFormattedTextFieldValue = (
  fieldValue: FormTextFieldValueType | number,
): string => {
  if (typeof fieldValue === 'number') {
    return `${fieldValue}`;
  }
  return ((fieldValue as string) || '–').trim();
};

const getFormattedMultipleChoiceValue = (
  field: FormField,
  fieldValue: string[],
  step: StepDefinition,
): string | null => {
  const formatted = fieldValue
    .map((optionValue) => {
      if (
        (field.component as FormMultipleChoiceComponentConfig).options
          .map((o) => o.value)
          .includes(optionValue)
      ) {
        return i18n.t(
          `${step.id}.formFields.${field.name}.options.${optionValue}.label`,
        );
      }
      return optionValue;
    })
    .join(', ');

  if (formatted.length > 0) {
    return formatted;
  }

  return null;
};

const getFormattedFileUploadValue = (
  rawValue: FormUploadFieldValueType,
): string | null => {
  if (!Array.isArray(rawValue)) {
    return null;
  }

  const filteredFileNames = rawValue
    .filter((value) => !!value.name)
    .map((value) => value.name);

  if (filteredFileNames.length < 1) {
    return null;
  }

  return filteredFileNames.join(', ');
};

const getFormattedSwitchCheckboxValue = (
  field: FormField<FormCheckboxValueType>,
  rawValue: FormCheckboxValueType,
  step: StepDefinition,
): string | null => {
  if (
    field.component &&
    (field.component as FormCheckboxComponentConfig).showLabel
  ) {
    return rawValue
      ? (i18n.t(
          `${step.id}.formFields.${field.name}.component.label`,
        ) as string)
      : null;
  }

  return (rawValue ? i18n.t('switch.yes') : i18n.t('switch.no')) as string;
};

export const getFormattedFieldData = (
  field: FormField,
  assistantContext: AssistantContext,
  stepContext: { [key: string]: FormValueType },
  step: StepDefinition,
  target?: OutputFormatterTypes,
): FieldOutputFormatterReturn => {
  const rawValue = stepContext[field.name];

  // Get formatted value by field type
  let formattedValue = rawValue as string;
  switch (field.type) {
    case FormFieldType.TextField:
      formattedValue =
        getFormattedTextFieldValue(rawValue as FormTextFieldValueType) ?? '–';
      break;
    case FormFieldType.MultipleChoice:
      formattedValue =
        getFormattedMultipleChoiceValue(
          field,
          rawValue as MultipleChoiceValue,
          step,
        ) ?? '–';
      break;
    case FormFieldType.FileUpload:
      formattedValue =
        getFormattedFileUploadValue(rawValue as FormUploadFieldValueType) ??
        '–';
      break;
    case FormFieldType.Switch:
    case FormFieldType.Checkbox:
      formattedValue =
        getFormattedSwitchCheckboxValue(
          field,
          rawValue as FormCheckboxValueType,
          step,
        ) ?? '–';
      break;
  }

  let formattedLabel = i18n.t(
    `${step.id}.formFields.${field.name}.label`,
  ) as string;
  if (i18n.te(`${step.id}.formFields.${field.name}.title`)) {
    formattedLabel = i18n.t(
      `${step.id}.formFields.${field.name}.title`,
    ) as string;
  }

  if (field.outputFormatter && typeof field.outputFormatter === 'function') {
    return field.outputFormatter({
      assistantContext,
      formattedLabel,
      formattedValue,
      i18n,
      rawValue,
      target,
    });
  }

  return {
    formattedLabel,
    formattedValue,
  };
};

export const getFormattedData = ({
  assistantContext,
  currentStepIndex,
  steps,
  target,
}: FormattedData): FormattedDataSummary => {
  const summary: FormattedDataSummary = [];
  steps.map((step, stepIndex) => {
    if (currentStepIndex && currentStepIndex <= stepIndex) {
      return;
    }
    if (step.isDisabled && step.isDisabled(assistantContext)) {
      return;
    }
    if (
      step.type &&
      step.type !== StepTypes.DEFAULT &&
      step.type !== StepTypes.CONTACT_FORM
    ) {
      return;
    }
    const formData = getStepContext(assistantContext, step);
    const summaryStep: {
      fields: FieldOutputFormatterReturn[];
      id: string;
      title: string;
    } = {
      fields: [],
      id: step.id,
      title: i18n.t(`${step.id}.title`) as string,
    };

    step.fields?.map((field) => {
      const formattedField = getFormattedFieldData(
        field,
        assistantContext,
        formData.value,
        step,
        target,
      );
      if (
        formattedField.formattedLabel !== false ||
        formattedField.formattedValue !== false
      ) {
        summaryStep.fields.push(formattedField);
      }
    });

    let summaryCategoryStep = summary.find(
      (summaryCategory) =>
        summaryCategory.categoryId === (step.categoryId ?? null),
    );
    if (!summaryCategoryStep) {
      summaryCategoryStep = {
        categoryId: step.categoryId ?? null,
        categoryTitle: step.categoryId
          ? (i18n.t(`categories.${step.categoryId}.title`) as string)
          : undefined,
        steps: [],
      };
      summary.push(summaryCategoryStep);
    }
    summaryCategoryStep.steps.push(summaryStep);
  });

  return summary;
};

export const getFormattedStepValues = (
  context: AssistantContext,
  step: StepDefinition,
  repeatIndex?: number,
  target?: OutputFormatterTypes,
): string => {
  const stepContext = getStepContext(context, step, repeatIndex).value;

  if (!stepContext) {
    return '';
  }

  if (!step.fields || step.fields.length === 0) {
    return '';
  }

  return step.fields
    ?.map((field) => {
      const fieldData = getFormattedFieldData(
        field,
        context,
        stepContext,
        step,
        target,
      );
      if (
        fieldData.formattedLabel === false &&
        fieldData.formattedValue === false
      ) {
        return null;
      }

      if (target === 'email' && fieldData.formattedLabel !== false) {
        return `**${fieldData.formattedLabel}**: ${
          fieldData.formattedValue === false ? '' : fieldData.formattedValue
        }`;
      }

      if (fieldData.formattedLabel !== false) {
        return `${fieldData.formattedLabel}: ${
          fieldData.formattedValue === false ? '' : fieldData.formattedValue
        }`;
      }

      return `${
        fieldData.formattedValue === false ? '' : fieldData.formattedValue
      }`;
    })
    .filter((value) => value !== null)
    .join('<br />');
};

export const getFormattedStepGroupValues = (
  context: AssistantContext,
  categoryId: StepCategoryId | string,
  steps: StepDefinition[],
  target?: OutputFormatterTypes,
) => {
  const groupSteps = steps.filter(
    (step) => step.categoryId && step.categoryId === categoryId,
  );
  const groupItemsCount = context[categoryId].length || 0;

  return [...Array(groupItemsCount)].map((_, i) =>
    groupSteps
      .map((step) => {
        let outputString = '';

        if (groupSteps.length > 1) {
          outputString += `## ${getStepTitle(step.id)}  `;
        }

        outputString += getFormattedStepValues(context, step, i, target);

        return outputString;
      })
      .join('<br /><br />'),
  );
};

/**
 * Use summary and create a markdown flavoured string (e.g. for email)
 */
export const getMarkdownData = (
  steps: StepDefinition[],
  assistantContext: AssistantContext,
): string => {
  const summary = getFormattedData({
    assistantContext,
    steps,
    target: OutputFormatterTypes.EMAIL,
  });

  let outputString = '';

  summary.map((summaryCategory) => {
    let stepHeadline = '##';
    if (summary.length > 1 && summaryCategory.categoryTitle) {
      outputString += `## ${summaryCategory.categoryTitle}\n\n`;
      stepHeadline = '###';
    }
    summaryCategory.steps.map((summaryStep, index) => {
      outputString += `${stepHeadline} ${summaryStep.title}\n\n`;
      summaryStep.fields.map((field) => {
        if (field.formattedLabel === false && field.formattedValue === false) {
          return;
        }
        if (
          field.formattedLabel !== false &&
          field.formattedValue !== false &&
          field.formattedValue !== '–'
        ) {
          outputString += `**${field.formattedLabel}**: `;
        }
        if (field.formattedValue !== false && field.formattedValue !== '–') {
          outputString += field.formattedValue;
        }
        outputString += '\n\n';
      });

      if (index < summaryCategory.steps.length - 1) {
        outputString += '\n\n---\n\n';
      }
    });
  });

  return outputString;
};

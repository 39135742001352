











import { defineComponent, onMounted } from '@vue/composition-api';
import GcAlert from '@/components/primitives/GcAlert.vue';
import GcCol from '@/components/primitives/GcCol.vue';
import GcRow from '@/components/primitives/GcRow.vue';
import { stepComponentProps } from '@/lib/steps/helper';

export default defineComponent({
  components: {
    GcAlert,
    GcCol,
    GcRow,
  },
  props: {
    ...stepComponentProps,
  },
  setup: (props) => {
    const zenloopSurveyId = process.env.VUE_APP_ZENLOOP_SURVEY_ID;

    onMounted(() => {
      if (zenloopSurveyId) {
        const divScript = document.getElementById('zenloop-script');
        const newScript = document.createElement('script');
        newScript.id = 'zl-website-overlay-loader';
        newScript.src = `https://zenloop-website-overlay-production.s3.amazonaws.com/loader/zenloop.load.min.js?survey=${zenloopSurveyId}`;
        divScript?.appendChild(newScript);

        const defaultProductId = process.env.VUE_APP_SHOP_PRODUCT_ID;
        const url = new URL(window.location.href);
        const productId =
          url.searchParams.get('product-id') ?? defaultProductId;

        if (props.assistantContext.contactForm && productId) {
          const zenloopRecipientScript = document.createElement('script');
          zenloopRecipientScript.text = `
            var Zenloop = window.Zenloop || {};
            Zenloop.recipient = {
              identity: '${props.assistantContext.contactForm.email}',
              identity_type: 'email',
              first_name: '${props.assistantContext.contactForm.firstname}',
              last_name: '${props.assistantContext.contactForm.lastname}',
              properties: {
                action: '${productId}'
              }
            };
          `;
          divScript?.appendChild(zenloopRecipientScript);
        }
      }
    });

    return { zenloopSurveyId };
  },
});

import { OutputFormatterTypes } from '@/consts/output-formatter-types';
import { StepDefinition } from '@/interfaces/step-definition';
import { getFormattedData } from '@/lib/formatter/context';
import { AssistantContext } from '~/steps/steps';

export const getProjectRequestDataForComment = (
  steps: StepDefinition[],
  assistantContext: AssistantContext,
): string => {
  const summary = getFormattedData({
    assistantContext,
    steps,
    target: OutputFormatterTypes.EMAIL,
  });

  let outputString = '';

  summary.map((summaryCategory) => {
    if (summary.length > 1 && summaryCategory.categoryTitle) {
      outputString += `\n${summaryCategory.categoryTitle}:\n\n`;
    }
    summaryCategory.steps.map((summaryStep) => {
      outputString += `\n${summaryStep.title}:\n`;
      summaryStep.fields.map((field) => {
        if (field.formattedLabel === false && field.formattedValue === false) {
          return;
        }
        if (field.formattedLabel !== false) {
          outputString += `${field.formattedLabel}: `;
        }
        if (field.formattedValue !== false) {
          outputString += field.formattedValue;
        }
        outputString += '\n';
      });
    });
  });

  return outputString;
};

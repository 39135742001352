/* eslint-disable no-console */
export const sendEvent = (
  event: string,
  params: Gtag.EventParams | Gtag.ControlParams,
) => {
  if (typeof gtag !== 'function') {
    console.warn('Google Tag Manager is not defined');
    return;
  }

  gtag('event', event, params);
};
/* eslint-enable no-console */

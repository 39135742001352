import { matomoTrackEvent } from '@/lib/analytics/matomo';
import { StepId } from '~/consts/assistant-steps';
import { StepDefinition } from '@/interfaces/step-definition';
import { StepTypes } from '@/consts/step-types';
import { sendGAEvent } from '@/lib/analytics/ga';
import { getProjectRequestDataForComment } from '@/lib/formatter/product-request';
import { AssistantContext } from '~/steps/steps';
import {
  ProductRequest,
  sendProductRequest,
} from '@/lib/submit/send-product-request';
import { sendMail } from '@/lib/submit/send-mail';
import { loadProductsByIds } from '@/lib/greenportal/load-products';
import { sendEvent } from '@/lib/analytics/gtag';

const config: StepDefinition = {
  hideSkipMessage: () => true,
  id: StepId.SUMMARY,
  // If this function is set, the next button isn't labelled "Weiter" but "Absenden"
  // and this function is executed. During submit the next button is disabled showing
  // a spinner. You can resolve the promise which will trigger the normal next-step
  // behaviour (usually a simple transition). If you reject the promise, the error
  // message you supply will be shown as an alert.
  submit: async ({ steps, assistantContext }) => {
    // Google-Analytics/Tag-Manager
    if (
      process.env.VUE_APP_GA_EVENT_ACTION &&
      process.env.VUE_APP_GA_EVENT_CATEGORY &&
      process.env.VUE_APP_GA_EVENT_LABEL
    ) {
      sendGAEvent({
        action: process.env.VUE_APP_GA_EVENT_ACTION,
        category: process.env.VUE_APP_GA_EVENT_CATEGORY,
        label: process.env.VUE_APP_GA_EVENT_LABEL,
      });
    }

    const DEFAULT_PRODUCT_ID = process.env.VUE_APP_SHOP_PRODUCT_ID;
    const url = new URL(window.location.href);
    const productId = url.searchParams.get('product-id') ?? DEFAULT_PRODUCT_ID;
    const { contactForm: contact } = assistantContext;
    const commentsData = getProjectRequestDataForComment(
      steps as StepDefinition[],
      assistantContext as AssistantContext,
    );

    const productUrl = `${process.env.VUE_APP_SHOP_URL}/detail/${productId}`;

    const productRequest: ProductRequest = {
      city: contact.city,
      comment: commentsData,
      email: contact.email,
      firstName: contact.firstname,
      lastName: contact.lastname,
      phone: contact.phone,
      productId,
      productUrl,
      salutationDisplayName: 'Keine Angabe',
      street: `${contact.street} ${contact.streetNumber}`,
      zipCode: contact.zipcode,
    };

    try {
      const results = await Promise.all([
        sendProductRequest(
          steps as StepDefinition[],
          assistantContext as AssistantContext,
          productRequest as ProductRequest,
        ),
        sendMail(
          steps as StepDefinition[],
          assistantContext as AssistantContext,
        ),
        loadProductsByIds([productId]),
      ]);

      const productDetails = results[2];

      // Get the name of the product to track it with matomo
      const productName =
        productDetails.length === 1 &&
        productDetails[0].name !== null &&
        productDetails[0].name.length > 0
          ? productDetails[0].name
          : '';

      if (productName.length === 0) {
        // eslint-disable-next-line no-console
        console.warn(
          `Couldn't get name of product with id ${productId}. Will track matomo event with the product url instead.`,
        );
      }

      if (process.env.VUE_APP_GTAG_CONVERSION) {
        if (
          typeof globalThis.UC_UI === 'object' &&
          typeof globalThis.UC_UI.getServicesBaseInfo === 'function'
        ) {
          const ucServices = globalThis.UC_UI.getServicesBaseInfo();
          const ucGoogleAds = ucServices.find(
            (s: { name: string }) => s.name && s.name === 'Google Ads',
          );
          const ucGoogleAdsConsent = ucGoogleAds?.consent?.status;
          if (ucGoogleAdsConsent) {
            sendEvent('conversion', {
              // eslint-disable-next-line @typescript-eslint/camelcase
              send_to: process.env.VUE_APP_GTAG_CONVERSION,
            });
          }
        }
      }

      matomoTrackEvent(
        'Shop',
        'Anfrage',
        productName.length > 0 ? productName : productRequest.productUrl,
      );
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
      throw new Error(
        `An error occurred while sending product request & sending the mail.}`,
      );
    }
  },
  type: StepTypes.SUMMARY,
};

export default config;

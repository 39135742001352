

























































































import { PropType, computed, defineComponent, ref } from '@vue/composition-api';
import { faAngleLeft, faAngleRight } from '@fortawesome/pro-solid-svg-icons';
import GcButton from '@/components/primitives/GcButton.vue';
import GcAlert from '@/components/primitives/GcAlert.vue';
import GcDialog from '@/components/primitives/GcDialog.vue';
import GcDivider from '@/components/primitives/GcDivider.vue';
import i18n from '@/plugins/i18n';
import {
  DealBreakerData,
  StepDefinition,
  stepSubmitFunctionType,
} from '@/interfaces/step-definition';
import { AssistantContext } from '~/steps/steps';

export default defineComponent({
  components: {
    GcAlert,
    GcButton,
    GcDialog,
    GcDivider,
  },
  props: {
    assistantContext: {
      default: null,
      type: Object as PropType<AssistantContext>,
    },
    showBackButton: {
      default: true,
      type: Boolean,
    },
    showNextButton: {
      default: true,
      type: Boolean,
    },
    step: {
      required: true,
      type: Object as PropType<StepDefinition>,
    },
    steps: {
      default: null,
      type: Array as PropType<StepDefinition[]>,
    },
    submit: {
      default: null,
      type: Function as PropType<stepSubmitFunctionType>,
    },
    valid: {
      default: true,
      type: Boolean,
    },
  },
  setup: (props, { emit }) => {
    const next = () => {
      emit('next');
    };

    const prev = () => {
      emit('prev');
    };

    const submitting = ref(false);
    const submitError = ref<boolean | string>(false);

    const isSubmit = computed<boolean>(() => !!props.submit);

    const doSubmit = async () => {
      if (typeof props.submit !== 'function') {
        return;
      }

      submitError.value = false;
      submitting.value = true;
      emit('submitting');

      props
        .submit({
          assistantContext: props.assistantContext,
          i18n,
          steps: props.steps as StepDefinition[],
        })
        .then(() => {
          emit('submit_success');
          next();
        })
        .catch((error) => {
          emit('submit_error');
          submitError.value = error;
        })
        .finally(() => {
          submitting.value = false;
        });
    };

    const dealBreakerShown = ref(false);
    const dealBreakerData = ref<DealBreakerData | false>(false);

    const isDealBreaker = (): boolean => {
      if (!props.step?.isDealBreaker) {
        return false;
      }

      dealBreakerData.value = props.step?.isDealBreaker(
        props.assistantContext as AssistantContext,
        i18n,
      );
      if (dealBreakerData.value === false) {
        return false;
      }

      dealBreakerShown.value = true;

      return true;
    };

    const dealBreakerHideContact = computed(
      () =>
        isDealBreaker() &&
        props.step?.hideSkipMessage &&
        props.step?.hideSkipMessage(props.assistantContext as AssistantContext),
    );

    const onClickGoToContact = () => {
      emit('transition', {
        event: 'JUMP_TO_CONTACTFORM',
      });
    };

    const handleNext = () => {
      if (isDealBreaker()) {
        return;
      }

      if (isSubmit.value) {
        doSubmit();
        return;
      }

      next();
    };

    return {
      dealBreakerData,
      dealBreakerShown,
      faAngleLeft,
      faAngleRight,
      handleNext,
      isSubmit,
      prev,
      submitError,
      submitting,
      dealBreakerHideContact,
      onClickGoToContact,
    };
  },
});

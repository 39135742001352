import component from './ContactForm.vue';
import { StepId } from '~/consts/assistant-steps';
import {
  FormCheckboxValueType,
  FormTextFieldValueType,
  StepDefinition,
} from '@/interfaces/step-definition';
import { AssistantStepContext } from '@/interfaces/context';
import { buildFormCheckbox, buildFormTextField } from '@/lib/forms/builder';
import { ValidationType } from '@/consts/validation-types';
import i18n from '@/plugins/i18n';
import { StepTypes } from '@/consts/step-types';

export interface ContactFormStepContext extends AssistantStepContext {
  firstname: FormTextFieldValueType;
  lastname: FormTextFieldValueType;
  street: FormTextFieldValueType;
  streetNumber: FormTextFieldValueType;
  zipcode: FormTextFieldValueType;
  city: FormTextFieldValueType;
  phone: FormTextFieldValueType;
  email: FormTextFieldValueType;
  newsletterConfirmation: FormCheckboxValueType;
  privacyConfirmation: FormCheckboxValueType;
}

const config: StepDefinition = {
  component,
  fields: [
    buildFormTextField('firstname', {
      required: true,
    }),
    buildFormTextField('lastname', {
      required: true,
    }),
    buildFormTextField('street', {
      required: true,
    }),
    buildFormTextField('streetNumber', {
      required: true,
    }),
    buildFormTextField('zipcode', {
      required: true,
      validation: [ValidationType.postalCodeDE],
    }),
    buildFormTextField('city', {
      required: true,
    }),
    buildFormTextField('phone', {
      required: true,
      validation: [ValidationType.phoneNumber],
    }),
    buildFormTextField('email', {
      component: {
        type: 'email',
      },
      required: true,
      validation: [ValidationType.email],
    }),
    buildFormCheckbox('newsletterConfirmation', {
      hideLabel: true,
      outputFormatter: (data) => ({
        formattedLabel:
          data.target === 'email'
            ? (i18n.t(
                'contactForm.formFields.newsletterConfirmation.summaryHeadline',
              ) as string)
            : data.formattedLabel,
        formattedValue: data.formattedValue,
      }),
      showHelperDialog: true,
    }),
    buildFormCheckbox('privacyConfirmation', {
      hideLabel: true,
      outputFormatter: (data) => ({
        formattedLabel:
          data.target === 'email'
            ? (i18n.t(
                'contactForm.formFields.privacyConfirmation.summaryHeadline',
              ) as string)
            : data.formattedLabel,
        formattedValue: data.formattedValue,
      }),
      required: true,
    }),
  ],

  id: StepId.CONTACT_FORM,
  // If set to contact-form, user have the option to skip previous steps and jump
  // directly to this step.
  type: StepTypes.CONTACT_FORM,
  typeOptions: {
    contactForm: {
      showNewsletterConfirmation: () => true,
    },
  },
};

export default config;

// Each step has its own identifier
export enum StepId {
  DATA_ENTRY = 'dataEntry',
  SUMMARY = 'summary',
  CONTACT_FORM = 'contactForm',
  DONE = 'done',
}

// You can optionally divide steps into groups,
// each one having its own identifier
export enum StepCategoryId {}

export enum StepGroupId {}

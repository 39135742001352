




































































































































































































import { computed, defineComponent, ref } from '@vue/composition-api';
import i18n from '@/plugins/i18n';
import AssistantStep from '@/components/AssistantStep.vue';
import GcCol from '@/components/primitives/GcCol.vue';
import GcForm from '@/components/primitives/GcForm.vue';
import GcRow from '@/components/primitives/GcRow.vue';
import AssistantStepNavigation from '@/components/AssistantStepNavigation.vue';
import { getStepContext } from '@/lib/context';
import { stepComponentProps } from '@/lib/steps/helper';
import FormField from '@/components/FormField.vue';
import { useBaseTransitions } from '@/lib/xstate/transitions';
import GcAlert from '@/components/primitives/GcAlert.vue';
import { zipCodesKasselArea } from '~/steps/contact-form/zipcode-data';
import GcTextField from '@/components/primitives/GcTextField.vue';
import { checkPostalCodeDE, checkRequired } from '@/lib/validators';
import GcDialog from '@/components/primitives/GcDialog.vue';
import GcDivider from '@/components/primitives/GcDivider.vue';

export default defineComponent({
  components: {
    AssistantStep,
    AssistantStepNavigation,
    FormField,
    GcAlert,
    GcCol,
    GcDialog,
    GcDivider,
    GcForm,
    GcRow,
    GcTextField,
  },
  props: {
    ...stepComponentProps,
  },
  setup: (props, { emit }) => {
    const { onNext, onPrev } = useBaseTransitions(
      emit,
      props.assistantContext,
      props.step,
    );
    const formData = getStepContext(props.assistantContext, props.step);
    const stepTitle = i18n.t(`${props?.step?.id}.title`) as string;

    const isMiscellaneousInputVisible = computed(() => {
      const [
        photovoltaicTarget,
      ] = props.assistantContext.dataEntry.photovoltaicTarget;
      return photovoltaicTarget === 'miscellaneous';
    });

    const formValid = ref(false);
    const zipCodeModalOpen = ref(false);

    const zipCodeRules = [checkRequired, checkPostalCodeDE];

    const zipCodeInput = (value: string) => {
      if (
        checkPostalCodeDE(value) === true &&
        !zipCodesKasselArea.includes(Number(value))
      ) {
        zipCodeModalOpen.value = true;
      }
    };

    return {
      zipCodeModalOpen,
      zipCodeInput,
      zipCodeRules,
      formData,
      formValid,
      isMiscellaneousInputVisible,
      onNext,
      onPrev,
      stepTitle,
    };
  },
});

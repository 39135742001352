/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-console */
export const matomoTrackEvent = (
  eventCategory: string,
  eventAction: string,
  eventName: string,
): void => {
  try {
    if (typeof (window as any)._paq === 'undefined') {
      console.warn('Matomo tracking not implemented on webpage.');
      return;
    }

    (window as any)._paq.push([
      'trackEvent',
      eventCategory,
      eventAction,
      eventName,
    ]);
  } catch (e) {
    console.error(e);
  }
};
/* eslint-enable no-console */
/* eslint-enable @typescript-eslint/no-explicit-any */

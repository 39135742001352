import dataEntry, { DataEntryStepContext } from '~/steps/data-entry';
import summary from '~/steps/summary';
import contactForm, { ContactFormStepContext } from '~/steps/contact-form';
import done from '~/steps/done';

// Add the imported StepDefinition from the steps in the order
// the steps should be shown in the assistant.
const steps = [dataEntry, contactForm, summary, done];

// Add the imported context interface for the steps
export interface AssistantContext {
  dataEntry: DataEntryStepContext;
  contactForm: ContactFormStepContext;
}

export default steps;

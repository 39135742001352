









import { defineComponent } from '@vue/composition-api';
export default defineComponent({
  props: {
    position: {
      type: String,
      default: 'bottom',
    },
  },
});

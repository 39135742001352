/* eslint-disable sort-keys-fix/sort-keys-fix */
export default {
  start: {
    buttonText: 'Assistenten starten',
    description:
      'Wir freuen uns, dass Sie sich für eine Solaranlage interessieren. Mit der Beantwortung der folgenden Fragen helfen Sie uns, sie von Anfang an optimal beraten zu können.',
    title: 'PV-Assistant',
    step: {
      label: 'Start',
    },
  },
  errorMessages: {
    checkNotEmpty: 'Feld darf nicht leer sein.',
    numericalValues: {
      minValue: 'Nur nummerische Werte sind erlaubt.',
    },
    realEstateConstructionYear: {
      maxYear: 'Das Baujahr soll maximal {year} sein.',
    },
  },
  dataEntry: {
    title: 'Dateneingabe',
    step: {
      label: 'Dateneingabe',
    },
    intro: {
      text:
        'Um Ihnen ein individuelles Angebot zu erstellen, benötigen wir ein paar Informationen zu Ihrem Haus und den Gegebenheiten vor Ort.',
    },
    headlines: {
      general: 'Allgemeine Angaben',
      realEstate: 'Angaben zum Objekt',
      placement: 'Montageort',
      documents: 'Bilder und weitere Angaben',
      roof: 'Dachform',
    },
    formFields: {
      zipCode: {
        label: 'Wo wohnen Sie?',
        description: 'Bitte tragen Sie hier Ihr Postleitzahl ein',
      },
      averageAnnualConsumption: {
        label: 'Wie hoch ist ihr durchschnittlicher Jahresverbrauch (in kWh)?',
        suffix: 'kWh',
      },
      realEstateConstructionYear: {
        label: 'Aus welchem Baujahr ist Ihre Immobilie?',
      },
      installedPhotovoltaicSystem: {
        label: 'Haben Sie bereits eine PV-Anlage installiert?',
        options: {
          yes: {
            label: 'Ja',
          },
          no: {
            label: 'Nein',
          },
        },
      },
      buildingOwner: {
        label: 'Sind Sie der Gebäudeeigentümer?',
        options: {
          yes: {
            label: 'Ja',
          },
          no: {
            label: 'Nein',
          },
        },
      },
      photovoltaicTarget: {
        label: 'Ich suche eine Photovoltaik für …',
        options: {
          detachedHouse: {
            label: 'Einfamilienhaus',
          },
          townHouse: {
            label: 'Reihenhaus',
          },
          apartmentBuilding: {
            label: 'Mehrfamilienhaus',
          },
          apartment: {
            label: 'Wohnung',
          },
          miscellaneous: {
            label: 'Sonstiges',
          },
        },
      },
      miscellaneousInput: {
        label: 'Sonstiges Ziel',
      },
      modulePlacement: {
        label: 'Wo sollen die Module platziert werden?',
        options: {
          houseRoof: {
            label: 'Hausdach',
          },
          gardenHouse: {
            label: 'Gartenhaus',
          },
          carportGarage: {
            label: 'Carport/Garage',
          },
          miscellaneous: {
            label: 'Sonstiges',
          },
        },
      },
      alignmentRoof: {
        label: 'In welche Richtung ist Ihr Dach ausgerichtet?',
        options: {
          east: {
            label: 'Ost',
          },
          south: {
            label: 'Süd',
          },
          west: {
            label: 'West',
          },
          eastWest: {
            label: 'Ost-West',
          },
          southEast: {
            label: 'Süd-Ost',
          },
          southWest: {
            label: 'Süd-West',
          },
          north: {
            label: 'Nord',
          },
          flatRoof: {
            label: 'Flachdach',
          },
          miscellaneous: {
            label: 'Sonstiges',
          },
        },
      },
      filesUpload: {
        label:
          'Bitte laden Sie hier ein Foto Ihres Zählerschrankes und Daches hoch',
      },
      chargingStationInterest: {
        label:
          'Haben Sie Interesse an einer Lademöglichkeit für Ihr Elektroauto?',
        options: {
          yes: {
            label: 'Ja',
          },
          no: {
            label: 'Nein',
          },
        },
      },
      roofType: {
        label: 'Wählen Sie Ihre Dachform',
        options: {
          gabledRoof: {
            label: 'Satteldach',
          },
          hipRoof: {
            label: 'Walmdach',
          },
          halfHippedRoof: {
            label: 'Krüppelwalmdach',
          },
          pentRoof: {
            label: 'Pultdach',
          },
          flatRoof: {
            label: 'Flachdach',
          },
        },
      },
    },
  },
  contactForm: {
    zipCodeNotAvailable: {
      title: 'Hinweis',
      label: 'Leider können wir Ihnen in ihrer Region kein Angebot machen.',
    },
    formFields: {
      phone: {
        label: 'Mobil-/Telefonnummer',
      },
      privacyConfirmation: {
        headline:
          'Hiermit stimme ich der <a href="https://www.sw-kassel.de/privatkunden/datenschutz/" target="_blank">Datenschutzerklärung</a> zu.',
      },
      newsletterConfirmation: {
        helperDialog: {
          text:
            'Angebote und individuelle Vorteile: z.B. zu Strom- und Gasprodukten, Fernwärme, Energiedienstleistungen, ÖPNV, Telekommunikation und den Bäder der Städtische Werke AG sowie der ihr verbundenen Unternehmen der Kasseler Verkehrs- und Versorgungs-GmbH. Je nach Thema und Wichtigkeit informieren wir Sie per Brief, E-Mail oder telefonisch. Diesen praktischen Infoservice können Sie jederzeit widerrufen.',
        },
      },
    },
  },
  done: {
    title: 'Fertig',
    step: {
      label: 'Fertig',
    },
  },
  message: {
    success: {
      title:
        'Vielen Dank für Ihre Anfrage. Wir nehmen hierzu in Kürze zu Ihnen Kontakt auf.',
    },
  },
};
/* eslint-enable sort-keys-fix/sort-keys-fix */
